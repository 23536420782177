import { AssistantModel, ChatHistoryLoadingState, ChatMessage, CosmosDBStatus } from "@api";
import { IPublicClientApplication } from "@azure/msal-browser";
import { assistantsQueryAtom } from "@state/AtomsQueries";
import { darkTheme, lightTheme } from "@styles/theme";
import { atom } from "jotai";
import { atomWithReducer, atomWithStorage } from "jotai/utils";
import { EMessageStatus } from "@types";
import { AppState } from "@state/AppProvider";
import { appStateReducer } from "@state/AppReducer";

// General
export const isInIframeAtom = atom(() => window.self !== window.top);
export const appStateAtom = atomWithReducer({
  isChatHistoryOpen: false,
  chatHistoryLoadingState: ChatHistoryLoadingState.NotStarted,
  chatHistory: null,
  filteredChatHistory: null,
  currentChat: null,
  isCosmosDBAvailable: {
    cosmosDB: false,
    status: CosmosDBStatus.NotConfigured,
  },
  user: null,
} as AppState, appStateReducer);

// Theme
export const isDarkThemeAtom = atomWithStorage("darkTheme", false);
export const isMobileAtom = atom(false);

export const currentThemeAtom = atom<typeof darkTheme | typeof lightTheme>(
  (get) => {
    return get(isDarkThemeAtom) ? darkTheme : lightTheme;
  }
);


// Chat
export const chatInputAtom = atom("");
export const menuWidthAtom = atom(300);
export const chatHistoryAtom = atom<ChatMessage[]>([]);
export const messageProcessingStatusAtom = atom<EMessageStatus>(EMessageStatus.NotRunning)

// Loading
export const isLoadingApplicationAtom = atom(false);
export const isLoadingResponseAtom = atom(false);
export const isLoadingAssistantAtom = atom(false);
export const isLoadingResourceAtom = atom(false);

// Modals
export const isModalLisOpenAtom = atom(false);
export const isModalFeedbackAtom = atom(false);
export const isModalUserTermsOpenAtom = atomWithStorage("termsAccepted", true);
export const isModalUserHelpOpenAtom = atom(false);

// Requests
export const abortControllersAtom = atom<AbortController[]>([]);
export const msalInstanceAtom = atom<IPublicClientApplication | null>(null);


// Assistant
export const currentAssistantAtom = atom<AssistantModel | null>(null);
export const assistantsIdsAtom = atom<string[]>(
  (get) => {
    const {data: assistantlist} = get(assistantsQueryAtom)

    if (assistantlist) {
      return assistantlist.map((a) => a.id)
    } else {
      return []
    }
  }
);