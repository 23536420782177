export const lightTheme = {
  colors: {
    background: "#FFFFFF",
    backgroundButton: "#23C12E",
    backgroundQuestion: "#EDF1F2",
    backgroundQuestionInput: "#FFFFFF",
    backgroundConfig: "#F9F9F9",
    backgroundToggleg: "#D9D9D9",
    backgroundTogglegHover: "#B7B7B7",
    color: "#4D4D4D",
    colorButton: "#FFFFFF",
    colorSecundary: "#B7B7B7",
    colorSection: "#303030",
    icon: "#23C12E",
    border: "#696969",
    borderSection: "#EAEEF0",
  },
};

export const darkTheme = {
  colors: {
    background: "#303030",
    backgroundButton: "#87D78A",
    backgroundQuestion: "#363636",
    backgroundQuestionInput: "#363636",
    backgroundConfig: "#383838",
    backgroundToggleg: "#87D78A",
    backgroundTogglegHover: "#23C12E",
    color: "#FFFFFF",
    colorButton: "#FFFFFF",
    colorSecundary: "#B7B7B7",
    colorSection: "#FFFFFF",
    icon: "#87D78A",
    border: "#696969",
    borderSection: "#555555",
  },
};
